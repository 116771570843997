import React, { useState, useEffect, useLayoutEffect } from 'react';
import { TextField, Button, Typography, Snackbar, SnackbarContent, Grid, Card, CardContent, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { AccessTime, Event, Place, DriveEta, Euro, People, Work } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    success: { backgroundColor: '#4caf50' },
    error: { backgroundColor: '#f44336' },
    buttonContainer: { display: 'flex', justifyContent: 'center', marginTop: theme.spacing(3) },
    mapContainer: { width: '100%', height: '400px', marginTop: theme.spacing(4) },
    card: { marginTop: theme.spacing(4), padding: theme.spacing(2), borderRadius: 8 },
    detailIcon: { color: theme.palette.primary.main, marginRight: theme.spacing(1) },
    formCard: { padding: theme.spacing(3), borderRadius: 8, backgroundColor: '#f9f9f9' },
    formField: { marginBottom: theme.spacing(2) },
}));

export function ConfirmationReservation() {
    const classes = useStyles();
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // Google Tag Manager code
    useEffect(() => {
        const script1 = document.createElement("script");
        script1.async = true;
        script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-16684973817";
        document.head.appendChild(script1);

        const script2 = document.createElement("script");
        script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16684973817');
        `;
        document.head.appendChild(script2);

        return () => {
            document.head.removeChild(script1);
            document.head.removeChild(script2);
        };
    }, []);

    const [reservationDetails, setReservationDetails] = useState({
        firstName: '',
        nom: '',
        email: '',
        date: new Date(),
        time: new Date(),
        lieuDepart: '',
        lieuArrivee: '',
        telephone: '',
        numPassengers: 1,
        numBags: 0,
        vehicleType: 'berline',
        distance: '',
        cost: '',
        duration: ''
    });

    const [errors, setErrors] = useState({
        firstName: false,
        nom: false,
        email: false,
        telephone: false,
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('success');
    const handleSnackbarClose = () => setSnackbarOpen(false);

    const [sendingReservation, setSendingReservation] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const dateDepart = new Date(searchParams.get('dateDepart')) || new Date();
        const timeDepart = new Date(searchParams.get('heureDepart')) || new Date();

        const lieuDepartId = searchParams.get('origin');
        const lieuArriveeId = searchParams.get('destination');

        setReservationDetails((prevDetails) => ({
            ...prevDetails,
            date: dateDepart,
            time: timeDepart,
            numPassengers: searchParams.get('numPassengers') || 1,
            numBags: searchParams.get('numBags') || 0,
            vehicleType: searchParams.get('vehicleType') || 'berline',
            distance: searchParams.get('distance') || '',
            cost: searchParams.get('cost') || '',
            duration: searchParams.get('duration') || ''
        }));

        // Fetch the addresses for both the origin and destination using place_ids
        const fetchAddressFromPlaceId = async (placeId) => {
            try {
                const response = await axios.get(`${apiUrl}/place-address`, {
                    params: { place_id: placeId },
                });

                const { address, name, url } = response.data; // Récupère `name` et `address`

                return { address, name, url };
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'adresse :', error);
                return { address: 'Adresse introuvable', name: '' };
            }
        };

        // Fetch addresses and update the state, then display the route on the map
        const fetchAddresses = async () => {
            if (lieuDepartId && lieuArriveeId) {
                const lieuDepartDetails = await fetchAddressFromPlaceId(lieuDepartId);
                const lieuArriveeDetails = await fetchAddressFromPlaceId(lieuArriveeId);

                setReservationDetails((prevDetails) => ({
                    ...prevDetails,
                    lieuDepart: lieuDepartDetails.address || '',
                    lieuDepartName: lieuDepartDetails.name || '',
                    lieuArrivee: lieuArriveeDetails.address || '',
                    lieuArriveeName: lieuArriveeDetails.name || '',
                    urlDepart: lieuDepartDetails.url || '',
                    urlArrivee : lieuArriveeDetails.url || '',
                }));

                // Draw route on map after setting addresses
                drawRouteOnMap(lieuDepartDetails.address, lieuArriveeDetails.address);
            } else {
                console.error("Les place_id de départ ou de destination sont manquants.");
            }
        };

        fetchAddresses();
    }, [location.search, apiUrl]);

const handleInputChange = (e) => {
        const { name, value } = e.target;
        setReservationDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSendingReservation(true);

        const requiredFields = ['firstName', 'nom', 'email', 'telephone', 'lieuDepart', 'lieuArrivee'];
        const newErrors = {};
        requiredFields.forEach((field) => {
            newErrors[field] = !reservationDetails[field];
        });

        if (Object.values(newErrors).some(Boolean)) {
            setErrors(newErrors);
            setSnackbarMessage('Veuillez remplir tous les champs du formulaire');
            setSnackbarColor('error');
            setSnackbarOpen(true);
            setSendingReservation(false);
            return;
        }

        try {
            await axios.post(`${apiUrl}/send-reservation-email`, {
                ...reservationDetails,
                date: reservationDetails.date.toLocaleDateString('fr-FR'),
                time: reservationDetails.time.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }),
            });
            setSnackbarMessage('Réservation envoyée avec succès');
            setSnackbarColor('success');
            setSnackbarOpen(true);


            // Envoyer l'événement de conversion à Google Ads
            window.gtag('event', 'conversion', {
                send_to: 'AW-16684973817/R9VcCLei-OYZEPn9gZQ-', // label de conversion
            });
        } catch (error) {
            console.error('Erreur lors de l\'envoi de la réservation:', error);
            setSnackbarMessage('Erreur lors de l\'envoi de la réservation');
            setSnackbarColor('error');
            setSnackbarOpen(true);
        }
        setSendingReservation(false);
    };

    const drawRouteOnMap = (originAddress, destinationAddress) => {
        if (!window.google) {
            console.error("Google Maps JavaScript API isn't loaded.");
            return;
        }

        const map = new window.google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            center: { lat: 47.2184, lng: -1.5536 }, // Default center, e.g., Nantes
        });
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        directionsRenderer.setMap(map);

        directionsService.route(
            {
                origin: originAddress,
                destination: destinationAddress,
                travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
                if (status === 'OK') {
                    directionsRenderer.setDirections(result);
                } else {
                    console.error('Error fetching directions:', status);
                }
            }
        );
    };

    return (
        <>
            <Typography variant="h4" align="center" gutterBottom>
                Confirmer ma réservation
            </Typography>

            {/* Trip Details Card */}
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h6" align="center" gutterBottom>
                        Détails du trajet
                    </Typography>
                    <Divider style={{ marginBottom: 16 }} />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Event className={classes.detailIcon} />
                            {reservationDetails.date.toLocaleDateString('fr-FR')}
                        </Grid>
                        <Grid item xs={6}>
                            <AccessTime className={classes.detailIcon} />
                            {reservationDetails.time.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })}
                        </Grid>
                        <Grid item xs={12}>
                            <Place className={classes.detailIcon} />
                            <strong>Départ : {reservationDetails.lieuDepartName || ''}</strong><br />
                            {reservationDetails.lieuDepart || 'Adresse indisponible'}
                        </Grid>
                        <Grid item xs={12}>
                            <Place className={classes.detailIcon} />
                            <strong>Destination : {reservationDetails.lieuArriveeName || ''}</strong><br />
                            {reservationDetails.lieuArrivee || 'Adresse indisponible'}
                        </Grid>
                        <Grid item xs={6}>
                            <DriveEta className={classes.detailIcon} />
                            Distance : {reservationDetails.distance} km
                        </Grid>
                        <Grid item xs={6}>
                            <Euro className={classes.detailIcon} />
                            Coût : {reservationDetails.cost} €
                        </Grid>
                        <Grid item xs={6}>
                            <AccessTime className={classes.detailIcon} />
                            Durée : {reservationDetails.duration} minutes
                        </Grid>
                        <Grid item xs={6}>
                            <DriveEta className={classes.detailIcon} />
                            Véhicule : {reservationDetails.vehicleType}
                        </Grid>
                        <Grid item xs={6}>
                            <People className={classes.detailIcon} />
                            Passagers : {reservationDetails.numPassengers}
                        </Grid>
                        <Grid item xs={6}>
                            <Work className={classes.detailIcon} />
                            Bagages : {reservationDetails.numBags}
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>

            {/* Map */}
            <div id="map" className={classes.mapContainer}></div>

            {/* Contact Form */}
            <Typography variant="h5" align="center" gutterBottom style={{ marginTop: '20px' }}>
                Mes informations de contact
            </Typography>
            <Card className={classes.formCard}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                required
                                label="Prénom"
                                variant="outlined"
                                name="firstName"
                                value={reservationDetails.firstName}
                                onChange={handleInputChange}
                                error={errors.firstName}
                                helperText={errors.firstName && "Ce champ est requis"}
                                className={classes.formField}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                required
                                label="Nom"
                                variant="outlined"
                                name="nom"
                                value={reservationDetails.nom}
                                onChange={handleInputChange}
                                error={errors.nom}
                                helperText={errors.nom && "Ce champ est requis"}
                                className={classes.formField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                label="Email"
                                variant="outlined"
                                name="email"
                                value={reservationDetails.email}
                                onChange={handleInputChange}
                                error={errors.email}
                                helperText={errors.email && "Ce champ est requis"}
                                className={classes.formField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                label="Téléphone"
                                variant="outlined"
                                name="telephone"
                                value={reservationDetails.telephone}
                                onChange={handleInputChange}
                                error={errors.telephone}
                                helperText={errors.telephone && "Ce champ est requis"}
                                className={classes.formField}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            variant="contained"
                            style={{ backgroundColor: '#B5DAFC', color: 'black' }}
                            disabled={sendingReservation}
                        >
                            {sendingReservation ? 'Envoi en cours...' : 'Je confirme ma réservation'}
                        </Button>
                    </div>
                </form>
            </Card>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <SnackbarContent className={classes[snackbarColor]} message={snackbarMessage} />
            </Snackbar>
        </>
    );
}

export default ConfirmationReservation;
