import React, {useLayoutEffect} from 'react';
import { Typography, List, ListItem, ListItemText} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import ReservationButton from "../Components/ReservationButton";

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'list-item',
        listStyleType: 'disc',
        marginLeft: theme.spacing(4), // Augmenter l'espacement à gauche pour décaler les items

    },
}));
export function ConditionsGeneralesDeVentes() {
    const classes = useStyles();

    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <>
            <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '20px' }}>
                CONDITIONS GÉNÉRALES DE VENTES
            </Typography>
            <Typography variant="h6" paragraph>
                ARTICLE 1. NOTRE OFFRE
            </Typography>

            <Typography variant="body1" paragraph>
                {process.env.REACT_APP_BUSINESS_NAME} est une société de transport de personnes, permettant aux particuliers ainsi
                qu’aux professionnels de bénéficier d’un service adapté à leurs besoins, dans le cadre de leurs
                déplacements 24h/24 et 7j/7.
            </Typography>

            <Typography variant="body1" paragraph>Notre société est soumise à la réglementation de la société {process.env.REACT_APP_BUSINESS_NAME}, et est inscrite sous le numéro SIRET {process.env.REACT_APP_BUSINESS_SIRET}.
            </Typography>

            <Typography variant="h6" paragraph>
                ARTICLE 2. RÉSERVATION D'UNE COURSE
            </Typography>

            <Typography variant="body1" paragraph>
                2.1 Réservation
            </Typography>

            <Typography variant="body1" paragraph>
                Quelque soit le type de réservation, le client devra fournir les informations obligatoires suivantes
                :
            </Typography>

            <Typography variant="body1" paragraph>
                Informations sur le client :
                <List>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="nom et prénom;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="n° de Téléphone;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="e-mail;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="adresse de résidence." />
                    </ListItem>
                </List>
            </Typography>

            <Typography variant="body1" paragraph>Détails de la course :
                <List>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="date;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="lieu de départ et d’arrivée;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="nombre de personnes;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="bagages ou non." />
                    </ListItem>
                </List>
                Les bagages ayant des dimensions exceptionnelles ou des volumes très importants, a nécessité de siège
                enfant/bébé.
            </Typography>

            <Typography variant="body1" paragraph>Ces informations doivent être obligatoirement précisées en ligne lors de la demande de
                réservation.
            </Typography>

            <Typography variant="body1" paragraph>
                {process.env.REACT_APP_BUSINESS_NAME} s’efforce d’assurer la disponibilité du site et de l’Application 24 heures sur
                24, et 7 jours sur 7. Cependant, il peut arriver que l’accès au Site ou à l’Application soit
                interrompu dans le cadre d’opérations de maintenance, de mises à niveau matérielle ou logicielle, de
                réparations d’urgence du Site, ou par suite de circonstances indépendantes de la volonté de {process.env.REACT_APP_BUSINESS_NAME} (comme par exemple, défaillance des liaisons et équipements de télécommunications). {process.env.REACT_APP_BUSINESS_NAME} s’engage à prendre toutes les mesures raisonnables pour limiter ces perturbations,
                pour autant qu’elles lui soient imputables.
            </Typography>

            <Typography variant="body1" paragraph>Les Utilisateurs reconnaissent et acceptent que {process.env.REACT_APP_BUSINESS_NAME} n’assume envers eux aucune responsabilité pour toute indisponibilité, suspension ou
                interruption du site ou du service et ne puisse être tenue responsable des préjudices directs et
                indirects de toute nature résultant de ce fait.
            </Typography>

            <Typography variant="body1" paragraph>
                2.1.1 Réservation en ligne
            </Typography>

            <Typography variant="body1" paragraph>
                Nous préconisons une réservation au minimum 2h avant le début de chaque course.
            </Typography>

            <Typography variant="body1" paragraph>Conformément aux
                présentes CGV, le Client reconnaît que les Services auxquels il a souscrits sont disponibles
                immédiatement et ne peuvent de ce fait, et conformément à l’article L.221-18 du code de la
                consommation, faire l’objet du droit de se rétracter.
            </Typography>

            <Typography variant="body1" paragraph>{process.env.REACT_APP_BUSINESS_NAME} se réserve le droit de
                suspendre le compte du Client, après une mise en demeure et un préavis de 7 jours, dans l’hypothèse
                où le Client ne respectait pas ses obligations au titre des présentes CGV.</Typography>

            <Typography variant="body1" paragraph>
                Les obligations du
                Client, au même titre que celles de {process.env.REACT_APP_BUSINESS_NAME}, peuvent être suspendues en cas de force
                majeure conformément aux présentes CGV.
            </Typography>

            <Typography variant="body1" paragraph>L’email de confirmation de paiement reçu lors du règlement
                de la course commandée constitue la seule confirmation définitive de réservation et garanti la
                présence du chauffeur.
            </Typography>

            <Typography variant="body1" paragraph>
                2.1.2 Réservation par téléphone
            </Typography>

            <Typography variant="body1" paragraph>
                La réservation devra être effectuée au minimum 2h avant le départ et durant les horaires de
                réservations. Pour toute réservation en deçà de ce délai, la société se réserve le droit à un délai
                de retard de 30 min pour arriver au lieu de départ.
            </Typography>

            <Typography variant="body1" paragraph>
                2.2 Prix – Conditions de paiement
            </Typography>

            <Typography variant="body1" paragraph>
                2.2.1 Détermination du Prix
            </Typography>

            <Typography variant="body1" paragraph>
                Tous les itinéraires réservés par nos clients sont calculés par le système de cartographie Google
                Maps qui est notre référence unique et conditionne le tarif final. Les courses sont calculées en
                fonction du trajet dont le temps de course estimé est le plus rapide.
            </Typography>

            <Typography variant="body1" paragraph>Le chauffeur est libre de
                changer d’itinéraire en fonction des conditions de circulation sans que le prix en soit modifié pour
                le client.
            </Typography>

            <Typography variant="body1" paragraph> Le système de réservation {process.env.REACT_APP_BUSINESS_NAME} prend en compte des temps de trajet qui
                sont fondés sur une observation quotidienne du trafic local.
            </Typography>

            <Typography variant="body1" paragraph>
                {process.env.REACT_APP_BUSINESS_NAME} ne saurait être tenu pour responsable des conditions de trafic qui pourraient entraîner un temps de trajet plus long
                que celui estimé lors de la réservation initiale. Il est de la responsabilité du client de prévoir
                lors de sa réservation le temps nécessaire à ses déplacements. De manière générale, {process.env.REACT_APP_BUSINESS_NAME} ne pourra être tenue responsable pour retard, et ce quelle que soit son origine (congestion
                du trafic, conditions de circulation difficiles, travaux de voirie, accidents, panne du véhicule,
                etc.), conformément à l’article 3 des présentes conditions générales de vente.
            </Typography>

            <Typography variant="body1" paragraph>
                Lorsque le client
                commande une course au départ de l’aéroport, il lui est notamment recommandé, lors de sa
                réservation, de prendre en compte le temps nécessaire à sa descente d’avion ainsi qu’à la
                récupération de ses bagages avant de déterminer l’heure de départ de la course.
            </Typography>

            <Typography variant="body1" paragraph>
                2.2.2 Moyens de paiement
            </Typography>

            <Typography variant="body1" paragraph>
                Le tarif est mentionné TTC et inclut la TVA au taux en vigueur.
                Les prestations réservées par le client doivent être réglées par carte bancaire.
                Les moyens de paiement suivants sont acceptés :
                <List>
                    <ListItem>
                        <ListItemText className={classes.listItem} primary="les cartes bancaires;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="paiement par virement pour les entreprises." />
                    </ListItem>
                </List>
                Le paiement du prix est réalisé par carte bancaire via un système de paiement électronique sécurisé, STRIPE. En communiquant ses coordonnées bancaires, le Client accepte par avance et sans conditions que {process.env.REACT_APP_BUSINESS_NAME} procède à la transaction sécurisée et autorise par avance sa banque à débiter son compte du montant de la prestation et des suppléments après la course (attente, parking, arrêt supplémentaire, changement d’adresse de destination) si tel est le cas.
            </Typography>

            <Typography variant="body1" paragraph>
                Le Client peut s’il le souhaite enregistrer sa carte de crédit pour toutes les Commandes. Dans le cas où le Client ne souhaite pas enregistrer sa carte de crédit pour toutes les Commandes, il doit enregistrer ses informations de carte de crédit lors de chaque Commande.
            </Typography>

            <Typography variant="body1" paragraph>
                En communiquant ses coordonnées bancaires, le Client accepte que la Société procède à la transaction sécurisée dès lors qu’il finalise une Commande. Le Client autorise donc, dès lors qu’il finalise une Commande, sa banque à débiter son compte à la vue des enregistrements ou des relevés transmis par la Société.
            </Typography>

            <Typography variant="body1" paragraph>
                En cas de paiement par le client via PAYPAL et AMEX (American Express), un supplément de 5% du montant total sera facturé.
            </Typography>

            <Typography variant="body1" paragraph>
                La réservation ainsi ne sera réputée acceptée qu’après validation du paiement.
            </Typography>

            <Typography variant="body1" paragraph>
                Toute facture ne faisant l’objet d’aucune contestation (écrite) du Client dans un délai de 30 jours à compter de sa date d’envoi sera réputée acceptée par ce dernier. Une contestation ne peut en aucun cas justifier un retard dans le règlement de la totalité de la facture.
            </Typography>

            <Typography variant="body1" paragraph>
                2.3 Conditions d’annulation
            </Typography>

            <Typography variant="body1" paragraph>
                Annulation de la réservation avant la prise en charge:
                En cas d’annulation par le client ayant déjà effectué le paiement, les frais suivants seront
                facturés :

                <List component="ol">
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="plus de 24 heures avant la réservation*: la totalité de la prestation est remboursée;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="entre 6 heures et 24 heures avant le début de la prestation : 50% du montant de la prestation
                est remboursé;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="moins de 6 heures avant la réservation: Aucun remboursement ne sera effectué
                *Pour les courses dont le montant est supérieur à 100€, un avoir du montant de la course sera
                accordé." />
                    </ListItem>
                </List>
            </Typography>

            <Typography variant="body1" paragraph>
                2.4 Retard
            </Typography>

            <Typography variant="body1" paragraph>

                <List component="ol">
                   <ListItem >
                        <ListItemText className={classes.listItem} primary="60 minutes sont gratuites à partir de l’horaire de rendez-vous fixé par le client lors de sa réservation pour les trajets aéroports;" />
                    </ListItem>
                   <ListItem >
                        <ListItemText className={classes.listItem} primary="15 minutes sont gratuites à partir de l’horaire de rendez-vous fixé par le client lors de sa réservation pour les trajets gares et autres;" />
                    </ListItem>
                   <ListItem >
                        <ListItemText className={classes.listItem} primary="au delà, les frais suivants sont facturés selon l’option d’attente choisie par le client (par défaut Option 1): - 0,45€ par minute." />
                    </ListItem>
                   <ListItem >
                        <ListItemText primary="OPTION ATTENTE EN CAS DE RETARD:" />
                    </ListItem>
                    <List component="ul" className={classes.listItem}>
                        <ListItem>
                            <ListItemText className={classes.listItem} primary="option 1 (par défaut lors de la réservation) : 15 minutes d’attente offertes, au-delà la course est réputée effectuée et celle-ci vous est facturée;" />
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listItem} primary="option 2 : Au delà des 15 minutes offertes, le chauffeur vous attend encore 1 heure facturée 0,45€/minute;" />
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listItem} primary="option 3 : Au delà des 15 minutes offertes, le chauffeur vous attend encore 2 heures facturées 0,45€/minute;" />
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listItem} primary="option 4 : Au delà des 15 minutes offertes, le chauffeur vous attend encore 3 heures facturées 0,45€/minute;" />
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listItem} primary="option 5 : Au delà des 15 minutes offertes, le chauffeur vous attend encore 4 heures facturées 0,45€/minute." />
                        </ListItem>
                    </List>
                   <ListItem >
                        <ListItemText primary="OPTION ATTENTE EN AÉROPORT:" />
                    </ListItem>
                    <List component="ul" className={classes.listItem}>
                        <ListItem>
                            <ListItemText className={classes.listItem} primary="option 1 : 60 minutes d’attente offertes, au-delà la course est réputée effectuée et celle-ci vous est facturée;" />
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listItem} primary="option 2 (par défaut lors de la réservation) : Au delà des 60 minutes offertes, le chauffeur vous attend encore 1 heure facturée 0,45€/minute;" />
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listItem} primary="option 3 : Au delà des 60 minutes offertes, le chauffeur vous attend encore 2 heures facturées 0,45€/minute;" />
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listItem} primary="option 4 : Au delà des 60 minutes offertes, le chauffeur vous attend encore 3 heures facturées 0,45€/minute;" />
                        </ListItem>
                        <ListItem>
                            <ListItemText className={classes.listItem} primary="option 5 : Au delà des 60 minutes offertes, le chauffeur vous attend encore 4 heures facturées 0,45€/minute." />
                        </ListItem>
                        </List>
                </List>
            </Typography>

            <Typography variant="body1" paragraph>
                Pour une attente illimitée du chauffeur en gare ou aéroport, le client devra souscrire à une
                mise à disposition de voiture avec chauffeur avec une facturation à l’heure.
            </Typography>

            <Typography variant="body1" paragraph>
                2.5 Modifications d’une commande
            </Typography>

            <Typography variant="body1" paragraph>
                Toute modification effectué à moins de 24H ne pourra être garantie par {process.env.REACT_APP_BUSINESS_NAME}. Les
                modifications de dernières minutes ne seront prises en compte qu’en fonction des possibilités
                et peuvent donner lieu à des suppléments ou des frais.
            </Typography>

            <Typography variant="body1" paragraph>
                2.6 Facture
            </Typography>

            <Typography variant="body1" paragraph>
                La facture définitive sera délivrée par e-mail sur demande du client après la course quelque
                soit le montant de celle-ci.
            </Typography>

            <Typography variant="h6" paragraph>
                ARTICLE 3. OBLIGATIONS DES PARTIES
            </Typography>

            <Typography variant="body1" paragraph>
                 {process.env.REACT_APP_BUSINESS_NAME} s’engage à mettre tout en œuvre pour acheminer sain et sauf le voyageur à
                destination, dans le délai prévu avec lui.
                La responsabilité de  {process.env.REACT_APP_BUSINESS_NAME} couvre les préjudices corporels directs causés aux voyageurs,
                depuis la montée dans le véhicule jusqu’à la descente, ces deux actions exclues.  {process.env.REACT_APP_BUSINESS_NAME} ne
                pourra être tenue responsable si les dommages sont causés par le fait du voyageur, par le fait d’un tiers, ou par la nature des bagages et/ou de leurs emballages.  {process.env.REACT_APP_BUSINESS_NAME} est souscripteur
                d’une assurance Responsabilité Civile Professionnelle.
            </Typography>

            <Typography variant="body1" paragraph>
                Ne donnent droit à aucune indemnité ou remboursement, les retards sur les délais de transport
                dus à des difficultés de circulation (bouchons, routes barrées, accidents, déviations, conditions
                météo, événements divers, etc…).  {process.env.REACT_APP_BUSINESS_NAME} s’engage néanmoins à étudier avec attention
                toute demande de remboursement faite par un client. Cette étude n’entraîne pas un
                remboursement automatique et pourrait consister le cas échéant en l’octroi d’un avoir
                éventuel.
            </Typography>

            <Typography variant="body1" paragraph>
                En cas de détérioration du véhicule par le client (y compris vomissement), celui-ci sera tenu
                pour responsable et devra s’acquitter d’une compensation forfaitaire de 150€. {process.env.REACT_APP_BUSINESS_NAME} ne pourra en aucun cas être tenue responsable pour tout retard incombant
                initialement au voyageur.
            </Typography>

            <Typography variant="h6" paragraph>
                ARTICLE 4. DISPOSITIONS DIVERSES
            </Typography>

            <Typography variant="body1" paragraph>
                Tous les passagers, à l’avant et à l’arrière, doivent attacher leur ceinture individuelle de
                sécurité.
            </Typography>

            <Typography variant="body1" paragraph>
                En cas de dégradation du véhicule, par exemple lors de l’ouverture intempestive des portières,
                les dégâts causés seront à la charge du Client.
            </Typography>

            <Typography variant="body1" paragraph>
                Si le chauffeur s’aperçoit que le Client commet une infraction, par exemple, l’usage de
                stupéfiants, il a comme consigne stricte d’arrêter immédiatement la prestation en cours. Cet
                arrêt ne donnera en aucun cas le droit au remboursement de la prestation.
            </Typography>

            <Typography variant="body1" paragraph>
                 {process.env.REACT_APP_BUSINESS_NAME} se réserve le droit d’interrompre la prestation en cours si le comportement des
                Clients met en péril la sécurité et la dignité du chauffeur et/ou l’image de la société.
            </Typography>

            <Typography variant="body1" paragraph>
                Il est interdit de manger dans les véhicules.
            </Typography>

            <Typography variant="body1" paragraph>
                Pour des raisons de sécurité, il est interdit de fumer, de transporter des substances
                inflammables, explosives, corrosives ou toxiques, et plus généralement toute marchandise
                dangereuse. Il appartient au passager de vérifier que ses bagages ne contiennent pas de telles
                substances. En cas de dommage causé par les bagages ou le comportement du passager, la
                responsabilité de {process.env.REACT_APP_BUSINESS_NAME} ne pourra en aucun cas être recherchée.
            </Typography>

            <Typography variant="body1" paragraph>
                Le contenu des bagages mis dans le coffre du véhicule, notamment les objets fragiles ou
                délicats, restent sous la seule responsabilité du passager.
            </Typography>

            <Typography variant="body1" paragraph>
                 {process.env.REACT_APP_BUSINESS_NAME} ne saurait être tenue responsable et ne pourra garantir la restitution des effets
                personnels et bagages laissés ou oubliés à bord du véhicule, ni de l’état dans lequel ils
                pourraient être retrouvés.
            </Typography>

            <Typography variant="body1" paragraph>
                L’Utilisateur s’engage à utiliser les services de {process.env.REACT_APP_BUSINESS_NAME} de manière raisonnable et
                notamment, à ne pas présenter les caractères suivants au moment de sa prise en charge par le transporteur :
                <List component="ol">
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="état d’ébriété manifeste;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="dangerosité;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="nombre de personnes excédant la limite indiquée lors de la réservation;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="nombre de bagages excédant la limite indiquée lors de la réservation ou volume de bagages
supérieur à la capacité d’emport du véhicule;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="présence d’animaux non enfermés dans une cage ou dans un sac. Les chiens
d’aveugles sont exemptés de cette obligation;" />
                    </ListItem>
                    <ListItem >
                        <ListItemText className={classes.listItem} primary="comportement outrancier ou contraire aux bonnes mœurs." />
                    </ListItem>
                </List>
            </Typography>

            <Typography variant="body1" paragraph>
                Cet engagement est également pris pour le compte du Passager, s’il s’agit d’une personne
                différente de l’Utilisateur.
            </Typography>

            <Typography variant="body1" paragraph>
                En cas d’oubli, il est recommandé d’envoyer un email à l’adresse suivante : {process.env.REACT_APP_BUSINESS_MAIL}.
            </Typography>


            <Typography variant="h6" paragraph>
                ARTICLE 5. RÉCLAMATIONS
            </Typography>

            <Typography variant="body1" paragraph>
                Toute réclamation concernant la Prestation devra prendre la forme d’un courrier par lettre
                recommandée avec accusé de réception à l’adresse suivante :
            </Typography>

            <Typography variant="body1" paragraph>
                {process.env.REACT_APP_BUSINESS_NAME} SAS
                <br/>
                1 ALLEE DE L'ELOQUENCE
                <br/>
                44800 SAINT-HERBLAIN
            </Typography>

            <Typography variant="body1" paragraph>
                Toute facture ne faisant l’objet d’aucune contestation (écrite) du Client dans un délai de 30
                jours à compter de sa date d’envoi sera réputée acceptée par ce dernier. Une contestation ne
                peut en aucun cas justifier un retard dans le règlement de la totalité de la facture.
            </Typography>

            <Typography variant="h6" paragraph>
                ARTICLE 6. LITIGES
            </Typography>

            <Typography variant="body1" paragraph>
                Les présentes conditions générales de vente sont régies exclusivement par la loi française.
            </Typography>

            <Typography variant="body1" paragraph>
                Le consommateur peut saisir, soit l’une des juridictions territorialement compétente en vertu
                du code de procédure civile, soit la juridiction du lieu ou il demeurait au moment de la
                conclusions du contrat ou de la survenance du fait dommageable.
            </Typography>
            <Typography variant="body1" paragraph>
                Le tribunal de commerce de Nantes est seul compétent en cas de litige ou différent pour les
                contrats professionnels.
            </Typography>

            <Typography variant="body1" paragraph>
                Lorsque la responsabilité de  {process.env.REACT_APP_BUSINESS_NAME} est engagée à la suite d’une faute de sa part, la
                réparation ne s’applique qu’aux seuls dommages directs, personnels et certains que le client à
                subis, à l’exclusion expresse de la réparations de tous dommages et/ou préjudices indirects et
                immatériels, tels que les préjudices financiers et commerciaux.
            </Typography>

            <Typography variant="h6" paragraph>
                ARTICLE 7. DROIT D’ACCÈS AUX DONNÉES ET DÉCLARATION DE CONFIDENTIALITÉ
            </Typography>

            <Typography variant="body1" paragraph>
                {process.env.REACT_APP_BUSINESS_NAME} collecte vos données via le site internet, la prise de demandes de réservation par
                email ou par téléphone et via l’application mobile.
            </Typography>

            <Typography variant="body1" paragraph>
                {process.env.REACT_APP_BUSINESS_NAME} s’engage à ne pas divulguer vos coordonnées à des tiers, ou pour des utilisations
                tout autre que celles du scope de communication lié aux réservations de vos trajets. Si vous
                avez coché l’acceptation de réception de nos newsletters, nous serons amenés à vous envoyer
                des informations commerciales, ou sur l’entreprise {process.env.REACT_APP_BUSINESS_NAME}. Toutes les informations
                concernant votre localisation, votre IP collectées, vos données relatives aux transactions, vos
                préférences, ne servent qu’à faciliter les opérations de réservations, communications avec
                vous.
            </Typography>

            <Typography variant="body1" paragraph>
                Conformément à la loi Informatique et Libertés (loi n°78-17 du 6 janvier 1978 modifiée par la
                loi n°2004-801du 6 août 2004), {process.env.REACT_APP_BUSINESS_NAME}. Ainsi vous disposez
                d’un droit d’interrogation, d’accès, et d’opposition pour motifs légitimes relatif à l’ensemble
                des données vous concernant. Vous pouvez exercer ces droits en vous adressant à :  </Typography>

            <Typography variant="body1" paragraph>
                {process.env.REACT_APP_BUSINESS_NAME} SAS
                <br/>
                1 ALLEE DE L'ELOQUENCE
                <br/>
                44800 SAINT-HERBLAIN
            </Typography>

            <Typography variant="body1" paragraph>
                ou à {process.env.REACT_APP_BUSINESS_MAIL}.
            </Typography>

            <Typography variant="h6" paragraph>
                ARTICLE 8. RÉCLAMATION ET MÉDIATION
            </Typography>

            <Typography variant="body1" paragraph>
                En cas de contestation liée à l’Application et/ou interprétation des présentes CGV, le Client a
                la possibilité de recourir à une procédure de médiation conventionnelle ou à tout autre mode
                alternatif de règlement des différends.
            </Typography>

            <Typography variant="body1" paragraph>
                Conformément à l’ordonnance n° 2015-1033 du 20 août 2015 et au décret d’application n°
                2015-1382 du 30 octobre 2015, tout différend ou litige dit de consommation, sous réserve de
                l’article L. 612-2 du code de la consommation, peut faire l’objet d’un règlement amiable par
                médiation auprès de la plateforme de règlement en ligne des litiges.
            </Typography>

            <Typography variant="body1" paragraph>
                Pour soumettre un litige au médiateur de la consommation, le Client peut remplir le
                formulaire de la plateforme de règlement en ligne des litiges en cliquant ici.
            </Typography>

            <Typography variant="body1" paragraph>
                Quel que soit le moyen utilisé pour saisir le médiateur, la demande devra contenir les
                éléments suivants pour être traitée avec rapidité : ses coordonnées postales, électroniques et
                téléphoniques ainsi que les nom et adresse de {process.env.REACT_APP_BUSINESS_NAME}, un exposé succinct des faits et la
                preuve des démarches préalables que vous avez effectuées auprès de {process.env.REACT_APP_BUSINESS_NAME}.
            </Typography>

            <Typography variant="h6" paragraph>
                ARTICLE 9. DIVISIBILITÉ
            </Typography>

            <Typography variant="body1" paragraph>
                En cas de nullité d’une quelconque disposition des présentes CGV, celle-ci est réputée non-
                écrite et les autres dispositions conservent leur force et leur portée.
            </Typography>


            <Typography variant="h6" paragraph>
                ARTICLE 10. ACCEPTATION DES CONDITIONS GÉNÉRALES DE VENTE
            </Typography>

            <Typography variant="body1" paragraph>
                Le client reconnaît avoir pris connaissance, lors de sa réservation, des conditions générales de
                vente.
            </Typography>
            <Typography variant="body1" paragraph>
                L’acceptation d’un devis par le client et la confirmation de sa commande impliquent l’entière
                adhésion du client aux présentes conditions générales de vente et l’acceptation sans réserve de
                toutes les dispositions y étant énoncées.
            </Typography>
            <Typography variant="body1" paragraph>
                {process.env.REACT_APP_BUSINESS_NAME} se réserve le droit de les modifier à tout moment.
            </Typography>
            <Typography variant="body1" paragraph>
                Au cas où l’une quelconque des dispositions des présentes Conditions Générales de Vente
                serait déclarée nulle ou réputée non écrite, toutes les autres dispositions resteraient
                applicables.
            </Typography>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </>
    );
}
