import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import LonguesDistancesImage from "../longuesdistances.png";
import {Link} from 'react-router-dom';
import ReservationButton from "../Components/ReservationButton";
import ExpandableContent from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: 'flex', // Utilise flexbox pour le conteneur
        justifyContent: 'center', // Centre l'image horizontalement dans le conteneur
        alignItems: 'center', // Centre verticalement l'image (si nécessaire)
        width: '100%', // Assure que le conteneur occupe toute la largeur disponible
        margin: '0 auto', // Centrer le conteneur dans la page, s'il a une largeur maximale définie
        paddingBottom: '20px', // Ajoute de l'espace en dessous de l'image
    },
    image: {
        width: '100%',
        height: 'auto',
        maxWidth: '300px', // Limite la largeur maximale de l'image
        display: 'block',
    },
}));

export function LonguesDistances() {
    const classes = useStyles();
    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <div className={classes.container}>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Longues distances</Typography>
            <div className={classes.imageContainer}>
                <Link to="/"> {/* Lien vers la racine du site */}
                    <img
                        src={LonguesDistancesImage}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <ExpandableContent>
                <Typography variant="body1" paragraph>
                    {process.env.REACT_APP_BUSINESS_NAME} peut s’occuper de tous vos transferts depuis et vers les
                    aéroports
                    de la région et au-delà, notre objectif, c’est de faire en sorte que vous puissiez
                    voyager sereinement. Nous vous proposons de réaliser le trajet de ou vers l’aéroport
                    à bord d’un véhicule haut de gamme, confortable et propre.
                </Typography>
                <Typography variant="body1" paragraph>
                    Votre chauffeur privé vous fournira une prestation de grande qualité. Il sera discret,
                    ponctuel et courtois. Il a pour mot d’ordre de vous conduire au terminal de l’aéroport
                    à temps et ceci dans des conditions de confort extrêmes, il prendra en charge vos
                    bagages.
                </Typography>
                <Typography variant="body1" paragraph>
                    Pour faciliter le contact et identifier votre chauffeur à la sortie de la zone de
                    débarquement, ce dernier sera porteur d’un panneau indiquant votre nom ou celui de
                    votre client ou d’une entreprise.
                </Typography>
                <Typography variant="body1" paragraph>
                    {/** Ajout du texte "Transport Toutes Distances" */}
                    Vous êtes à la recherche d’un chauffeur privé toutes distances au départ de Nantes,
                    pour vous déplacer facilement sans aucune limite ou contrainte, vous pouvez faire
                    confiance à nos chauffeurs.
                    Vous arrivez à l’aéroport de Nantes, notre chauffeur privé, vous conduira où vous le
                    souhaitez dans la région nantaise mais également vers toute la France et même
                    l’Europe selon vos besoins.
                    Embarquez à bord de de nos véhicules haut de gamme et laissez-vous conduire en
                    toute quiétude. Une fois à bord de notre véhicule, vous pourrez vous reposer et
                    profiter de votre trajet pour admirer les villes que vous traverserez. Nous vous
                    conduirons directement à votre destination.
                </Typography>
                <Typography variant="body1" paragraph>
                    Pour profiter de nos services de transferts aéroport, réserver dès maintenant notre chauffeur
                    privé.
                </Typography>
            </ExpandableContent>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </div>
    );
}
