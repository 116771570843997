import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PlageImage from "../plage.png";
import {Link} from 'react-router-dom';
import ReservationButton from "../Components/ReservationButton";
import ExpandableSection from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: 'flex', // Utilise flexbox pour le conteneur
        justifyContent: 'center', // Centre l'image horizontalement dans le conteneur
        alignItems: 'center', // Centre verticalement l'image (si nécessaire)
        width: '100%', // Assure que le conteneur occupe toute la largeur disponible
        margin: '0 auto', // Centrer le conteneur dans la page, s'il a une largeur maximale définie
        paddingBottom: '20px', // Ajoute de l'espace en dessous de l'image
    },
    image: {
        width: '100%',
        height: 'auto',
        maxWidth: '300px', // Limite la largeur maximale de l'image
        display: 'block',
    },
}));

export function Plage() {
    const classes = useStyles();
    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <div className={classes.container}>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Transferts vers les ports et
                plages de l'Atlantique</Typography>
            <div className={classes.imageContainer}>
                <Link to="/"> {/* Lien vers la racine du site */}
                    <img
                        src={PlageImage}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <ExpandableSection>
                <Typography variant="body1" paragraph>
                    Si vous prévoyez de vous rendre dans une station balnéaire de la région, réservez votre transfert
                    avec {process.env.REACT_APP_BUSINESS_NAME}. Nous sommes des professionnels de la route expérimentés,
                    nous vous acheminerons en toute sécurité jusqu’à votre hôtel, votre résidence de vacances ou votre
                    bateau.
                </Typography>
                <Typography variant="body1" paragraph>
                    Rejoindre les plages de la Côte Atlantique peut se faire par vos propres moyens ou par le biais de
                    notre chauffeur privé {process.env.REACT_APP_BUSINESS_NAME}. Que ce soit depuis votre domicile ou
                    votre résidence de vacances, vous serez ainsi serein pour rejoindre les plages et cités balnéaires
                    comme :
                </Typography>
                <Typography variant="body1" paragraph>
                    <ul>
                        <li>Des Sables d’Olonne;</li>
                        <li>De Saint-Gilles-Croix-de-Vie;</li>
                        <li>De La Baule;</li>
                        <li>De Saint-Jean-de-Monts;</li>
                        <li>De La Tranche-sur-Mer;</li>
                        <li>De La Rochelle.</li>
                        {/* Ajouter les autres plages et cités balnéaires */}
                    </ul>
                </Typography>

                <Typography variant="body1" paragraph>
                    Que vous soyez en croisière sur un paquebot ou en voilier, lors de votre escale sur la Côte
                    Atlantique, nous pouvons vous organiser des excursions et vous accueillir sur le quai pour vous
                    faire visiter la région. Le retour au bateau se fera aux horaires indiqués.
                </Typography>
                <Typography variant="body1" paragraph>
                    Pour profiter de nos services de transferts vers les plages de la Côte Atlantique, réservez dès
                    maintenant notre chauffeur privé.
                </Typography>
            </ExpandableSection>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </div>
    );
}
