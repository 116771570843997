import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import TPMRImage from "../tpmr.png";
import ReservationButton from "../Components/ReservationButton";
import ExpandableContent from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: 'flex', // Utilise flexbox pour le conteneur
        justifyContent: 'center', // Centre l'image horizontalement dans le conteneur
        alignItems: 'center', // Centre verticalement l'image (si nécessaire)
        width: '100%', // Assure que le conteneur occupe toute la largeur disponible
        margin: '0 auto', // Centrer le conteneur dans la page, s'il a une largeur maximale définie
        paddingBottom: '20px', // Ajoute de l'espace en dessous de l'image
    },
    image: {
        width: '100%',
        height: 'auto',
        maxWidth: '300px', // Limite la largeur maximale de l'image
        display: 'block',
    },
}));

export function TransportTPMR() {
    const classes = useStyles();
    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>T.P.M.R (Transport de personnes
                à mobilité réduite)</Typography>
            <div className={classes.imageContainer}>
                <Link to="/"> {/* Lien vers la racine du site */}
                    <img
                        src={TPMRImage}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <ExpandableContent>
                <Typography variant="body1" paragraph>
                    T.P.M.R (Transport de personnes à mobilité réduite), pour les non-voyants et les personnes en
                    fauteuil roulant manuel et transférable sur un siège passager.
                    Beaucoup de personnes ont besoin de ce service que ce soit pour aller faire des courses ou
                    pour se rendre à des rendez-vous chez l’ophtalmologiste ou l’audioprothésiste, ou
                    encore pour aller voir des amis, des enfants et petits-enfants, mais aussi pour aller au spectacle
                    ou
                    au restaurant.
                    {process.env.REACT_APP_BUSINESS_NAME} assure le transport de personnes à mobilité réduite qui
                    peuvent se
                    déplacer, comme les non-voyants mais aussi ceux qui peuvent se transférer de leur fauteuil
                    roulant manuel au siège passager de nos véhicules.
                </Typography>
                <Typography variant="body1" paragraph>
                    Garantir la mobilité des personnes dépendantes.
                    La mobilité est un élément essentiel pour l’autonomie. Dans le cadre du maintien à domicile
                    d’une personne âgée, par exemple, avoir la possibilité de sortir de chez soi de façon
                    régulière permet de lutter contre l’isolement. Les personnes handicapées doivent également
                    pouvoir mener une vie quotidienne à l’extérieur, participer à des activités, se rendre à leurs
                    rendez-vous médicaux ou privés, faire leurs courses, etc. Or, il n’est pas toujours possible
                    pour la famille ou pour l’aidant d’accompagner un proche au quotidien.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Un savoir-être axé sur la bienveillance, le respect et l’écoute.</strong>
                    <br/>
                    <ul>
                        <li>Accompagner les personnes à mobilité réduite dans leurs trajets quotidiens</li>
                        <li>Transporter des personnes en véhicule léger</li>
                        <li>Planifier des trajets (itinéraires, étapes, horaires…)</li>
                        <li>Assurer la sécurité des personnes transportées et des équipements</li>
                        <li>Proposer un accompagnement adapté aux besoins des personnes et à leur degré de dépendance
                        </li>
                        <li>Adopter une posture bienveillante et attentive envers les personnes</li>
                    </ul>
                </Typography>
            </ExpandableContent>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </>
    );
}
