import React from 'react';
import { Helmet } from 'react-helmet';
import Favicon from '../vtc.ico';

const Header = () => {
    const businessName = process.env.REACT_APP_BUSINESS_NAME;
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{businessName}</title>
                <meta name="description" content="Chauffeur privé Nantes" />
                <link rel="icon" type="image/x-icon" href={Favicon}  />
            </Helmet>
        </div>
    );
}

export default Header;