import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import AeroportImage from "../aerport.jpeg";
import ReservationButton from "../Components/ReservationButton";
import ExpandableContent from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: 'flex', // Utilise flexbox pour le conteneur
        justifyContent: 'center', // Centre l'image horizontalement dans le conteneur
        alignItems: 'center', // Centre verticalement l'image (si nécessaire)
        width: '100%', // Assure que le conteneur occupe toute la largeur disponible
        margin: '0 auto', // Centrer le conteneur dans la page, s'il a une largeur maximale définie
        paddingBottom: '20px', // Ajoute de l'espace en dessous de l'image
    },
    image: {
        width: '100%',
        height: 'auto',
        maxWidth: '300px', // Limite la largeur maximale de l'image
        display: 'block',
    },
}));

export function Voyage() {
    const classes = useStyles();

    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Bon voyage</Typography>
            <ExpandableContent>
                <Typography variant="body1" paragraph>
                    Un départ en vacances, un vol ou un train à prendre, mieux vaut ne pas être en retard. Pour
                    être à l’heure sur le quai ou à l’embarquement, faites appel à {process.env.REACT_APP_BUSINESS_NAME}.
                    Laissez
                    votre voiture sécurisée dans votre garage, on se charge de vos transferts aller et retour vers
                    et depuis la gare ou l’aéroport de Nantes.
                </Typography>
                <Typography variant="body1" paragraph>
                    Aucun souci à vous faire, vous êtes déjà en vacances, on vient vous chercher à l’heure dite,
                    à l’adresse indiquée, on se charge de vos bagages et on vous dépose en toute sécurité et
                    quiétude à la gare ou à l’aéroport, devant votre terminal de départ.
                </Typography>
                <Typography variant="body1" paragraph>
                    A votre retour votre chauffeur privé, vous attendra même en cas de retard de train ou
                    d’avion, il sera là et ce sans aucun frais supplémentaire.
                </Typography>
                <Typography variant="body1" paragraph>
                    Pour profiter de notre forfait BONNES VACANCES, réservez dès maintenant votre chauffeur
                    privé.
                </Typography>
                <div className={classes.imageContainer}>
                    <Link to="/"> {/* Lien vers la racine du site */}
                        <img
                            src={AeroportImage}
                            alt={process.env.REACT_APP_BUSINESS_NAME}
                            className={classes.image}
                        />
                    </Link>
                </div>
            </ExpandableContent>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </>
    );
}
