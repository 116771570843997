import React from 'react';
import { Typography } from '@mui/material';
import { Link } from "react-router-dom";
import { Facebook, Twitter, Instagram } from '@mui/icons-material';
import '../Styles/footer.css';

// Obtenez l'année actuelle
const currentYear = new Date().getFullYear();

const Footer = () => {
    return (
        <footer className="footer">
            <div className="social-media-banner">
                <Typography variant="body1" className="social-media-text">
                    Suivez-nous sur les réseaux sociaux :
                </Typography>
                <div className="social-media-icons">
                    <a href={`https://www.facebook.com/profile.php?id=61559006231642`} target="_blank" rel="noopener noreferrer"
                       className="social-media-link">
                        <Facebook className="social-media-icon" />
                    </a>
                    <a href={`https://twitter.com/SafeAndFriendly`} target="_blank" rel="noopener noreferrer"
                       className="social-media-link">
                        <Twitter className="social-media-icon" />
                    </a>
                    <a href={`https://www.instagram.com/${process.env.REACT_APP_BUSINESS_NAME_URL}`} target="_blank" rel="noopener noreferrer"
                       className="social-media-link">
                        <Instagram className="social-media-icon" />
                    </a>
                </div>
            </div>
            <Typography variant="body1" className="footer-link">
                <Link to="/" className="footer-link">
                    © {process.env.REACT_APP_BUSINESS_NAME} {currentYear}
                </Link>{' | '}
                <Link to="/mentions-legales" className="footer-link">
                    Mentions légales
                </Link>{' | '}
                <Link to="/condtions-generales-de-ventes" className="footer-link">
                    Conditions générales de ventes
                </Link>
            </Typography>
        </footer>
    );
}

export default Footer;
