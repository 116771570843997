import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import CallButton from './CallButton'; // Importez le composant CallButton si nécessaire
import { makeStyles } from '@mui/styles';
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Importer l'icône d'horloge

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '8px',
    },
}));

const ReservationButton = ({ buttonText, phoneNumber }) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.buttonContainer}>
                {/* Bouton de réservation */}
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AccessTimeIcon />} // Icône d'horloge à gauche du texte
                    component={Link}
                    to="/reserver"
                    style={{ backgroundColor: '#B5DAFC', '&:hover': { backgroundColor: '#ADD1FC' }, color: 'black' }}
                >
                    {buttonText}
                </Button>
            </div>

            <div className={classes.buttonContainer}>
                {/* Bouton d'appel */}
                <CallButton phoneNumber={phoneNumber} />
            </div>
        </>
    );
};

export default ReservationButton;
