import React from 'react';
import { Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import '../Styles/callbutton.css';

const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    return cleaned.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
};

const CallButton = ({ phoneNumber }) => {
    const handleCall = () => {
        // Envoyer l'événement de conversion à Google Ads
        if (window.gtag) {
            window.gtag('event', 'conversion', {
                'send_to': 'AW-16684973817/mncXCOKh-eYZEPn9gZQ-',
                'value': 1.0,
                'currency': 'EUR'
            });
        }

        // Lancer l'appel
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <Button
            variant="contained"
            color="success"
            startIcon={<PhoneIcon />}
            onClick={handleCall}
            style={{ backgroundColor: '#B5DAFC', color: 'black' }}
            className="call-button"
        >
            Appeler {formatPhoneNumber(phoneNumber)}
        </Button>
    );
};

export default CallButton;
