import React, { useLayoutEffect } from 'react';
import { Typography } from '@mui/material';
import ReservationButton from "../Components/ReservationButton";

export function MentionsLegales() {
    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <>
            <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '20px' }}>LES MENTIONS LÉGALES</Typography>

            <Typography variant="body1" paragraph>
                Éditeur : {process.env.REACT_APP_BUSINESS_NAME} SAS
            </Typography>

            <Typography variant="body1" paragraph>
                Adresse : {process.env.REACT_APP_BUSINESS_NAME} SAS
                <br />
                1 ALLEE DE L'ELOQUENCE 44800 SAINT-HERBLAIN
            </Typography>

            <Typography variant="body1" paragraph>
                SIRET : {process.env.REACT_APP_BUSINESS_SIRET}
            </Typography>

            <Typography variant="body1" paragraph>
                Tél. : {process.env.REACT_APP_BUSINESS_PHONE}
            </Typography>

            <Typography variant="body1" paragraph>
                EMAIL : {process.env.REACT_APP_BUSINESS_MAIL}
            </Typography>

            <Typography variant="body1" paragraph>
                Hébergement : Google
            </Typography>

            <Typography variant="h6" paragraph>
                DONNÉES PERSONNELLES
            </Typography>

            <Typography variant="body1" paragraph>
                La totalité des données communiquées par les internautes sont strictement confidentielles. En aucun cas elles ne pourront être transmises à quiconque. Conformément à la loi "Informatique et Libertés", les internautes disposent des droits d’accès, de rectification et d’opposition de l'ensemble des données nominatives, et seront en mesure de les exercer par courrier postal ou encore depuis notre page contact.
            </Typography>

            <Typography variant="h6" paragraph>
                DROITS D'AUTEURS ET LIENS HYPERTEXTES
            </Typography>

            <Typography variant="body1" paragraph>
                L'ensemble des droits de reproduction des textes, documents photographiques, iconographies et logos sont réservés sur {process.env.REACT_APP_BUSINESS_NAME}. Par ailleurs, le bon fonctionnement et la validité des liens hypertextes menant vers d’autres sites Internet n'engagent en rien la responsabilité de {process.env.REACT_APP_BUSINESS_NAME}.
            </Typography>

            <Typography variant="h6" paragraph>
                COOKIES
            </Typography>

            <Typography variant="body1" paragraph>
                Dans le cadre de la consultation de notre site {process.env.REACT_APP_BUSINESS_NAME}, des informations concernant la navigation des internautes peuvent être enregistrées dans des fichiers "Cookies" installés sur leur terminal. Ces derniers sont en droit d'exprimer à tout moment leurs demandes concernant ces cookies.
            </Typography>

            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </>
    );
}
