import React, { useLayoutEffect, useState } from 'react';
import { TextField, Button, Typography, Snackbar, SnackbarContent, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@mui/icons-material/Send';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        marginTop: theme.spacing(4),
        padding: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
    },
    success: {
        backgroundColor: '#4caf50', // Couleur verte pour le succès
    },
    error: {
        backgroundColor: '#f44336',
    },
    container: {
        marginTop: '20px', // Ajoute de l'espace au-dessus du container principal
        marginBottom: '10px', // Ajoute de l'espace en dessous du container principal
    },
}));

export const Contact = () => {
    const classes = useStyles();

    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        telephone: '',
        message: ''
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('success');
    const [loadingSnackbarOpen, setLoadingSnackbarOpen] = useState(false); // Ajout de l'état pour la Snackbar d'envoi en cours
    const apiUrl = process.env.REACT_APP_API_URL; // Utilisation de la variable d'environnement

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingSnackbarOpen(true); // Afficher la Snackbar d'envoi en cours
        try {
            // Envoyer les données du formulaire au serveur
            const response = await fetch(`${apiUrl}/send-email`, { // Utilisation de l'URL de l'API
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });
            const data = await response.json();
            console.log(data);
            if (data.success) {
                setSnackbarMessage('E-mail envoyé avec succès');
                setSnackbarColor('success');
            } else {
                setSnackbarMessage('Erreur lors de l\'envoi de l\'e-mail');
                setSnackbarColor('error');
            }
            setSnackbarOpen(true);
            // Réinitialiser le formulaire après l'envoi
            setFormData({
                name: '',
                email: '',
                telephone: '',
                message: ''
            });
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage('Erreur lors de l\'envoi de l\'e-mail');
            setSnackbarColor('error');
            setSnackbarOpen(true);
        } finally {
            setLoadingSnackbarOpen(false); // Cacher la Snackbar d'envoi en cours
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <>
            <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '20px' }}>Contactez-nous</Typography>

            <Typography variant="h6" align="left" gutterBottom>
                Nous contacter via notre formulaire :
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type="text"
                            name="name"
                            label="Nom"
                            variant="outlined"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            fullWidth
                            className="text-field"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type="email"
                            name="email"
                            label="E-mail"
                            variant="outlined"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            fullWidth
                            className="text-field"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            type="tel"
                            name="telephone"
                            label="Téléphone"
                            variant="outlined"
                            value={formData.telephone}
                            onChange={handleChange}
                            fullWidth
                            className="text-field"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="message"
                            label="Message"
                            variant="outlined"
                            multiline
                            rows={4}
                            value={formData.message}
                            onChange={handleChange}
                            required
                            fullWidth
                            className="text-field"
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" style={{ marginTop: '16px' }}>
                    <Button
                        type="submit"
                        variant="contained"
                        style={{ backgroundColor: '#B5DAFC', color: 'black' }}
                        startIcon={<SendIcon />}
                    >
                        Envoyer
                    </Button>
                </Grid>
            </form>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <SnackbarContent
                    className={snackbarColor === 'success' ? classes.success : classes.error}
                    message={snackbarMessage}
                />
            </Snackbar>

            {/* Snackbar pour l'envoi en cours */}
            <Snackbar
                open={loadingSnackbarOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                message="Envoi en cours..."
                action={<CircularProgress color="inherit" size={20} />}
            />
        </>
    );
};
