import React, {useLayoutEffect} from 'react';

import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import TourismeImage from "../tourisme.png"; // Remplacer par l'image appropriée
import ReservationButton from "../Components/ReservationButton"; // Importer le composant Link
import ExpandableSection from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: 'flex', // Utilise flexbox pour le conteneur
        justifyContent: 'center', // Centre l'image horizontalement dans le conteneur
        alignItems: 'center', // Centre verticalement l'image (si nécessaire)
        width: '100%', // Assure que le conteneur occupe toute la largeur disponible
        margin: '0 auto', // Centrer le conteneur dans la page, s'il a une largeur maximale définie
        paddingBottom: '20px', // Ajoute de l'espace en dessous de l'image
    },
    image: {
        width: '100%',
        height: 'auto',
        maxWidth: '300px', // Limite la largeur maximale de l'image
        display: 'block',
    },
}));

export function Tourisme() {
    const classes = useStyles();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Tourisme</Typography>
            <div className={classes.imageContainer}>
                <a href="/"
                   rel="noopener noreferrer"> {/* Lien vers la racine du site (remplacez par le lien souhaité) */}
                    <img
                        src={TourismeImage} // Utilisation de l'image appropriée
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </a>
            </div>
            <ExpandableSection>

                <Typography variant="body1" paragraph>
                    Un service tourisme sur-mesure, découvrez la ville de Nantes et la région en voiture avec un guide
                    chauffeur.
                    Pour une heure, une journée, une semaine, ou toute autre durée de votre choix, vous bénéficierez
                    d’un
                    guide
                    chauffeur expérimenté pour vous faire découvrir une région qu’il connaît parfaitement. Notre
                    chauffeur
                    sera
                    un partenaire parfait pour vous conduire dans tous les lieux que vous souhaitez visiter. Il pourra
                    également
                    vous conseiller sur les endroits à voir et les choses à faire absolument durant votre séjour.
                </Typography>
                <Typography variant="body1" paragraph>
                    Grâce à notre service, vous n’aurez pas à prendre les transports en commun ni à vous soucier de
                    stationner
                    votre voiture de location.
                </Typography>
                <Typography variant="body1" paragraph>
                    Les lieux touristiques à visiter en Pays de la Loire :
                </Typography>

                <ul>
                    <li><a href="https://www.chateau-angers.fr/" target="_blank" rel="noopener noreferrer">Château
                        d'Angers</a></li>
                    <li><a href="https://www.vendee-tourisme.com/lile-penotte" target="_blank"
                           rel="noopener noreferrer">Quartier
                        de l'Ile de Penotte</a></li>
                    <li><a href="https://brissac.net/" target="_blank" rel="noopener noreferrer">Château de Brissac</a>
                    </li>
                    <li><a
                        href="https://www.ot-saumur.fr/autour-du-cheval/le-cadre-noir-de-saumur-une-institution-prestigieuse/"
                        target="_blank" rel="noopener noreferrer">Le Cadre Noir</a></li>
                    <li><a href="https://www.fontevraud.fr/" target="_blank" rel="noopener noreferrer">Abbaye Royale de
                        Fontevraud</a></li>
                    <li><a href="https://www.ile-noirmoutier.com/fr/explorer-l-ile/le-passage-du-gois" target="_blank"
                           rel="noopener noreferrer">Le Passage du Gois</a></li>
                    <li><a href="https://www.chateaudebreze.com/" target="_blank" rel="noopener noreferrer">Château de
                        Brézé</a></li>
                    <li><a
                        href="https://actu.fr/pays-de-la-loire/le-mans_72181/la-cathedrale-saint-julien-du-mans-tout-savoir-sur-son-histoire_59682489.html"
                        target="_blank" rel="noopener noreferrer">Cathédrale de Saint-Julien de Mans</a></li>
                    <li><a href="https://www.chateau-serrant.net/" target="_blank" rel="noopener noreferrer">Château de
                        Serrant</a></li>
                    <li><a href="https://www.sarthetourisme.com/decouvrir/patrimoine-culturel/la-nuit-des-chimeres/"
                           target="_blank" rel="noopener noreferrer">La Nuit des Chimeres</a></li>
                    <li><a href="https://www.grand-blockhaus.com/" target="_blank" rel="noopener noreferrer">Le Grand
                        Blockhaus</a></li>
                    <li><a href="https://le-mystere-des-faluns.com/" target="_blank" rel="noopener noreferrer">Le
                        Mystère
                        des Faluns - Les Perrières</a></li>
                </ul>
                <Typography variant="body1" paragraph>
                    Les lieux touristiques à visiter en Loire Atlantique :
                </Typography>
                <ul>
                    <li><a href="https://www.chateaunantes.fr/" target="_blank" rel="noopener noreferrer">Château des
                        Ducs
                        de Bretagne</a></li>
                    <li><a href="https://tourisme-loireatlantique.com/a-decouverte-de-trentemoult/" target="_blank"
                           rel="noopener noreferrer">Village de Trentemoult</a></li>
                    <li><a href="https://www.levoyageanantes.fr/" target="_blank" rel="noopener noreferrer">Le Voyage à
                        Nantes</a></li>
                    <li><a href="https://tourisme-loireatlantique.com/guerande-cite-medievale-de-caractere/"
                           target="_blank"
                           rel="noopener noreferrer">Cité Médiévale de Guérande</a></li>
                    <li><a
                        href="https://www.saint-nazaire-musees.com/liste-ressources/retour-sur/histoire-sous-marin-espadon"
                        target="_blank" rel="noopener noreferrer">Sous-Marin l'Espadon</a></li>
                    <li><a href="https://www.terredesel.com/fr/" target="_blank" rel="noopener noreferrer">Terre De
                        Sel</a>
                    </li>
                    <li><a href="https://cathedrale-nantes.fr/" target="_blank" rel="noopener noreferrer">Cathédrale
                        Saint-Pierre et Saint-Paul de Nantes</a></li>
                    <li><a href="https://www.planetesauvage.com/" target="_blank" rel="noopener noreferrer">Planète
                        Sauvage</a></li>
                    <li><a href="https://museedartsdenantes.nantesmetropole.fr/horaires" target="_blank"
                           rel="noopener noreferrer">Musée d'Arts de Nantes</a></li>
                    <li><a href="https://www.labaule.fr/decouvrir-sortir/plage-et-nautisme-1/plage-de-la-baule"
                           target="_blank" rel="noopener noreferrer">Plage de la Baule</a></li>
                </ul>
                <Typography variant="body1" paragraph>
                    Pour profiter pleinement de la région lors de votre visite, réservez dès maintenant notre service de
                    tourisme.
                </Typography>
            </ExpandableSection>

            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </>
    );
}
