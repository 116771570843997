import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import RestaurantImage from "../restaurant.png";
import ReservationButton from "../Components/ReservationButton";
import ExpandableContent from "../Components/ExpandableText"; // Importez l'image du restaurant

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: 'flex', // Utilise flexbox pour le conteneur
        justifyContent: 'center', // Centre l'image horizontalement dans le conteneur
        alignItems: 'center', // Centre verticalement l'image (si nécessaire)
        width: '100%', // Assure que le conteneur occupe toute la largeur disponible
        margin: '0 auto', // Centrer le conteneur dans la page, s'il a une largeur maximale définie
        paddingBottom: '20px', // Ajoute de l'espace en dessous de l'image
    },
    image: {
        width: '100%',
        height: 'auto',
        maxWidth: '300px', // Limite la largeur maximale de l'image
        display: 'block',
    },
}));

export function RestaurantsClubs() {
    const classes = useStyles();

    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu


    return (
        <div className={classes.container}>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>
                Restaurants et Clubs à Nantes
            </Typography>
            <ExpandableContent>
                <Typography variant="body1" paragraph>
                    {process.env.REACT_APP_BUSINESS_NAME} peut vous déposer et vous ramener dans les restaurants et les
                    clubs à Nantes.
                    Profitez d'une soirée sans souci et laissez-nous vous accompagner pour vos sorties.
                </Typography>
                <div className={classes.imageContainer}>
                    <img
                        src={RestaurantImage}
                        alt="Restaurant à Nantes"
                        className={classes.image}
                    />
                </div>
                <Typography variant="body1" paragraph>
                    Nous vous offrons un service de transport fiable et sécurisé pour vous rendre à vos destinations
                    de choix dans la ville de Nantes. Que ce soit pour un dîner dans un restaurant renommé ou pour
                    passer une soirée dans un club animé, notre chauffeur privé vous garantit une expérience agréable
                    et sans tracas.
                </Typography>
            </ExpandableContent>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </div>
    );
}

export default RestaurantsClubs;
