import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import FemmeImage from "../femmes.jpeg";
import FemmeImage2 from "../femmes2.png";
import ReservationButton from "../Components/ReservationButton";
import ExpandableContent from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: 'flex', // Utilise flexbox pour le conteneur
        justifyContent: 'center', // Centre l'image horizontalement dans le conteneur
        alignItems: 'center', // Centre verticalement l'image (si nécessaire)
        width: '100%', // Assure que le conteneur occupe toute la largeur disponible
        margin: '0 auto', // Centrer le conteneur dans la page, s'il a une largeur maximale définie
        paddingBottom: '20px', // Ajoute de l'espace en dessous de l'image
    },
    image: {
        width: '100%',
        height: 'auto',
        maxWidth: '300px', // Limite la largeur maximale de l'image
        display: 'block',
    },
}));

export function Femme() {
    const classes = useStyles();
    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Femmes</Typography>
            <div className={classes.imageContainer}>
                <Link to="/"> {/* Lien vers la racine du site */}
                    <img
                        src={FemmeImage}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <Typography variant="body1" paragraph>
                Pour toutes vos sorties entre filles !
                Envie d’une soirée entre filles ou d’une journée shopping. Réservez un véhicule haut de
                gamme avec une « chauffeure » femme. En plus de l’aspect pratique évident à se déplacer en voiture avec
                chauffeur, {process.env.REACT_APP_BUSINESS_NAME} vous propose d’être conduite par une conductrice
                professionnelle plutôt qu’un
                chauffeur !
            </Typography>
            <ExpandableContent>
                <div className={classes.imageContainer}>
                    <Link to="/"> {/* Lien vers la racine du site */}
                        <img
                            src={FemmeImage2}
                            alt={process.env.REACT_APP_BUSINESS_NAME}
                            className={classes.image}
                        />
                    </Link>
                </div>
                <Typography variant="body1" paragraph>
                    Les compétences sont évidemment les mêmes, nous vous offrons simplement la possibilité
                    de choisir la solution la plus confortable pour vous. Notre équipe de chauffeures professionnelles
                    est
                    également à votre disposition pour vos
                    évènements privés comme un enterrement de vie de jeune fille.
                    Lors de votre réservation téléphonique, en ligne ou sur notre application (à télécharger
                    gratuitement), merci de stipuler que vous souhaitez une conductrice.
                </Typography>
                <Typography variant="body1" paragraph>
                    Pour profiter de la possibilité d’avoir une femme conductrice, réservez dès maintenant notre
                    chauffeure privé.
                </Typography>
            </ExpandableContent>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </>
    );
}
