import React, { useState } from 'react';
import { Typography, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';

// Import icons from @mui/icons-material
import AirportIcon from '@mui/icons-material/LocalAirport';
import TrainIcon from '@mui/icons-material/Train';
import SightseeingIcon from '@mui/icons-material/LocalSee';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import BusinessIcon from '@mui/icons-material/BusinessCenter';
import HotelIcon from '@mui/icons-material/Hotel';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DiamondIcon from '@mui/icons-material/Diamond';
import WomenIcon from '@mui/icons-material/Female';
import VacationIcon from '@mui/icons-material/Flight';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CarRepairIcon from '@mui/icons-material/Build';
import SchoolIcon from '@mui/icons-material/School';
import AccessibilityIcon from '@mui/icons-material/AccessibilityNew';
import AttractionsIcon from '@mui/icons-material/Attractions';

const useStyles = makeStyles((theme) => ({
    serviceItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '5px',
        textAlign: 'center',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        },
    },
    icon: {
        fontSize: 36, // Adjust icon size for the navbar
        marginBottom: '5px',
    },
    footerContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.appBar,
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
        padding: '0px 10px', // Add padding to the sides
        flexWrap: 'wrap',
        display: 'flex',
        alignItems: 'center',
    },
    safeFriendlyContainer: {
        position: 'absolute',
        bottom: '8%', // Align it similarly to the logo
        left: '35px', // Align it similarly to the logo
        color: 'white',
        textAlign: 'left',
        zIndex: 1000, // Ensure it stays on top of other elements
    },
    safeFriendlyText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: '18px', // Adjust the size as needed
    },
    logo: {
        width: 100,
        height: 'auto',
    },
    text: {
        color: 'black',
    },
    callButtonContainer: {
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(1),
    },
    navbarContainer: {
        display: 'flex',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        padding: theme.spacing(1, 0),
        scrollbarWidth: 'none', // Hide scrollbar for Firefox
        '&::-webkit-scrollbar': {
            display: 'none', // Hide scrollbar for Chrome/Safari
        },
    },
    navbarItem: {
        flex: '0 0 auto',
        padding: theme.spacing(1),
        textAlign: 'center',
        textDecoration: 'none',
        color: theme.palette.text.primary,
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    scrollContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        position: 'relative',
    },
    scrollContent: {
        display: 'flex',
        animation: '$scroll 40s linear infinite', // Augmenter la durée à 40s pour ralentir le défilement
    },
    '@keyframes scroll': {
        '0%': { transform: 'translateX(0)' },
        '100%': { transform: 'translateX(-50%)' }, // Ajustez cette valeur en fonction de la longueur du contenu
    },
    hideButton: {
        color: theme.palette.text.secondary, // Make the button text color subtle
    },
}));

const services = [
    { title: 'Aéroport', route: '/aeroport', icon: <AirportIcon /> },
    { title: 'Gare', route: '/gare', icon: <TrainIcon /> },
    { title: 'Business', route: '/business', icon: <BusinessIcon /> },
    { title: 'Hôtels', route: '/hotel', icon: <HotelIcon /> },
    { title: 'Restaurants & Clubs', route: '/restaurants-clubs', icon: <RestaurantIcon /> },
    { title: 'Tourisme', route: '/tourisme', icon: <SightseeingIcon /> },
    { title: 'Plages', route: '/plage', icon: <BeachAccessIcon /> },
    { title: 'Longues distances', route: '/longues-distances', icon: <DirectionsCarIcon /> },
    { title: 'Mariages', route: '/mariage', icon: <DiamondIcon /> },
    { title: 'Femmes', route: '/femme', icon: <WomenIcon /> },
    { title: 'Voyages', route: '/voyages', icon: <VacationIcon /> },
    { title: 'Panne & Rapatriement', route: '/panne', icon: <CarRepairIcon /> },
    { title: 'Transports scolaires', route: '/transport-scolaire', icon: <SchoolIcon /> },
    { title: 'Parcs d\'attractions', route: '/parcs-attractions', icon: <AttractionsIcon /> },
    { title: 'Transport TPMR', route: '/transport-tpmr', icon: <AccessibilityIcon /> },
    // Add more services here with their respective icons and routes
];

const ServiceItem = ({ title, icon, route, className }) => {
    const classes = useStyles();

    return (
        <Link to={route} className={className}>
            <div className={classes.serviceItem}>
                {icon}
                <Typography variant="caption">{title}</Typography>
            </div>
        </Link>
    );
};

const ServicesFooter = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isFooterVisible, setFooterVisible] = useState(true);

    const toggleFooterVisibility = () => {
        setFooterVisible(!isFooterVisible);
    };

    return (
        <>
            {isFooterVisible && (
                <>
                    <Grid container className={classes.footerContainer} alignItems="center" >
                        {!isMobile && (
                            <Grid item md={1} xs={1}>
                                <Typography variant="h6" align="center" style={{ color: 'black', marginBottom: '20px' }}>
                                    Nos prestations:
                                </Typography>
                            </Grid>
                        )}
                        <Grid item md={10} xs={isMobile ? 9 : 11}>
                            <div className={classes.scrollContainer}>
                                <div className={classes.scrollContent}>
                                    {services.map((service, index) => (
                                        <ServiceItem
                                            key={index}
                                            title={service.title}
                                            icon={service.icon}
                                            route={service.route}
                                            className={classes.navbarItem}
                                        />
                                    ))}
                                    {/* Dupliquer le contenu pour l'effet de défilement infini */}
                                    {services.map((service, index) => (
                                        <ServiceItem
                                            key={index + services.length} // Assurez-vous que la clé est unique
                                            title={service.title}
                                            icon={service.icon}
                                            route={service.route}
                                            className={classes.navbarItem}
                                        />
                                    ))}
                                </div>
                            </div>
                        </Grid>
                        {/* Le bouton de fermeture */}
                        {/* Ajout d'une colonne vide à droite du bouton sur mobile */}
                        {isMobile && <Grid item xs={1}></Grid>}
                        <Grid item md={1} xs={1} style={{ textAlign: 'right' }}>
                            <IconButton
                                className={classes.hideButton}
                                onClick={toggleFooterVisibility}
                                aria-label="Masquer la barre des services"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>

                        {/* Ajout d'une colonne vide à droite du bouton sur mobile */}
                        {isMobile && <Grid item xs={1}></Grid>}
                    </Grid>
                </>
            )}
        </>
    );
};

export default ServicesFooter;