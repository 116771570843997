import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PanneImage from "../panne.png";
import ReservationButton from "../Components/ReservationButton";
import ExpandableContent from "../Components/ExpandableText"; // Importez une image pour illustrer le service de panne

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: 'flex', // Utilise flexbox pour le conteneur
        justifyContent: 'center', // Centre l'image horizontalement dans le conteneur
        alignItems: 'center', // Centre verticalement l'image (si nécessaire)
        width: '100%', // Assure que le conteneur occupe toute la largeur disponible
        margin: '0 auto', // Centrer le conteneur dans la page, s'il a une largeur maximale définie
        paddingBottom: '20px', // Ajoute de l'espace en dessous de l'image
    },
    image: {
        width: '100%',
        height: 'auto',
        maxWidth: '300px', // Limite la largeur maximale de l'image
        display: 'block',
    },
}));

export function Panne() {
    const classes = useStyles();
    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <>
            <div className={classes.container}>
                <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Panne Assistance et
                    Rapatriement</Typography>
                <div className={classes.imageContainer}>
                    <img
                        src={PanneImage}
                        alt="Assistance en cas de panne"
                        className={classes.image}
                    />
                </div>
                <ExpandableContent>
                    <Typography variant="body1" paragraph>
                        Tous les jours et quel que soit l’heure, notre équipe d’assistance et de rapatriement est à
                        votre service en cas de panne ou d’indisponibilité de votre véhicule. Vous pouvez nous
                        contacter par téléphone. Notre chauffeur viendra vous chercher au lieu convenu pour
                        vous transférer rapidement et en toute sécurité vers votre destination, votre domicile
                        ou le garage. Grâce à notre service de transport, vous pourrez vous déplacer en
                        toute simplicité et vous aurez la garantie que votre panne n’impactera pas votre
                        mobilité.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Ce service est accessible sur l’agglomération nantaise ainsi que l’ensemble du
                        département de Loire Atlantique.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Concernant la prise en charge financière de votre rapatriement, sachez que notre
                        service d’assistance est pris en charge suivant le contrat établi avec votre assureur. A
                        cet effet, nous vous fournirons une facture qu’il vous faudra remettre à votre assureur.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Pour profiter de nos services en cas de panne ou d’immobilisation de votre véhicule,
                        réservez dès maintenant notre chauffeur privé.
                    </Typography>
                </ExpandableContent>
                <ReservationButton
                    buttonText="Réserver"
                    phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
                />
            </div>
        </>
    );
}
