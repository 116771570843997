import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '8px',
    },
}));

const ContactButton = ({ buttonText }) => {
    const classes = useStyles();

    return (
        <div className={classes.buttonContainer}>
            {/* Bouton de contact */}
            <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/contact"
                style={{ backgroundColor: '#B5DAFC', '&:hover': { backgroundColor: '#ADD1FC' }, color: 'black'  }}
            >
                {buttonText}
            </Button>
        </div>
    );
};

export default ContactButton;
